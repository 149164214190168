import { replaceStrings } from "@piggy/lib";
import { currentLanguage } from "../modules/settings";
export function getTranslatedText(tx) {
    if (!tx)
        return "";
    // Try to get the translation for the current language
    if (tx[currentLanguage]) {
        return tx[currentLanguage];
    }
    // If not found, try to get the 'default' translation
    if (tx.default) {
        return tx.default;
    }
    // If 'default' is not available, fall back to the first available translation
    return tx[Object.keys(tx)[0]] ?? "";
}
export function getSpendRuleLabel(text, credits, creditsName, discount, discountType) {
    if (!text)
        return "";
    const getDiscountType = () => {
        if (discountType === "percentage") {
            return `${discount}%`;
        }
        else if (discountType === "fixed") {
            const currency = window.piggyWcSettings.currency.symbol;
            return `${currency}${discount}`;
        }
        return `${discount}`;
    };
    return replaceStrings(text, [
        {
            "{{ credits_currency }}": creditsName ?? "",
            "{{ credits }}": credits?.toString() ?? "0",
            "{{ discount }}": getDiscountType(),
        },
    ]);
}
