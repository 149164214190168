export class WooCommerceStoreApiError extends Error {
    status;
    statusText;
    data;
    message;
    constructor(status, statusText, data) {
        super(`WooCommerceStoreApiError: ${statusText}`);
        this.status = status;
        this.statusText = statusText;
        this.data = data;
        this.message = data;
    }
}
